<template>
  <div>
    <md-table
      md-card
      :md-sort.sync="currentSort"
      :md-sort-fn="customSort"
      :md-sort-order.sync="currentOrder"
      table-header-color="blue"
      :value="transactions"
    >
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Список транзакций</h1>
        </div>

        <div class="md-toolbar-section-end">
          <md-field :md-clearable="true" @md-clear="dateFrom = ''">
            <md-datepicker
              v-model="dateFrom"
              :disabled="isLoaderVisible"
              :md-model-type="String"
            >
              <label>От</label>
            </md-datepicker>
          </md-field>

          <md-field :md-clearable="true" @md-clear="dateTo = ''">
            <md-datepicker
              v-model="dateTo"
              :disabled="isLoaderVisible"
              :md-model-type="String"
            >
              <label>До</label>
            </md-datepicker>
          </md-field>

          <md-button
            class="md-raised md-primary"
            :disabled="isLoaderVisible"
            @click="changePage(1)"
          >
            Найти
          </md-button>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Никнейм" md-sort-by="user">
          {{ item.userName }}
        </md-table-cell>

        <md-table-cell md-label="ID" md-sort-by="transactionId">
          {{ item.transaction.transactionId }}
        </md-table-cell>

        <md-table-cell md-label="Дата" md-sort-by="date">
          {{ formatDate(item.transaction.date) }}
        </md-table-cell>

        <md-table-cell md-label="Кол-во кристаллов">
          {{ getItemsCount(item.transaction, "crystal") }}
        </md-table-cell>

        <md-table-cell md-label="Кол-во фрагментов">
          {{ getItemsCount(item.transaction, "fragments") }}
        </md-table-cell>

        <md-table-cell md-label="Кол-во золота">
          {{ getItemsCount(item.transaction, "gold") }}
        </md-table-cell>

        <md-table-cell md-label="Кол-во сфер">
          {{ getItemsCount(item.transaction, "sphere") }}
        </md-table-cell>

        <md-table-cell md-label="Кол-во свитков">
          {{ getItemsCount(item.transaction, "summoningScroll") }}
        </md-table-cell>

        <md-table-cell md-label="ID предмета" md-sort-by="itemId">
          {{ item.transaction.itemId }}
        </md-table-cell>
      </md-table-row>
    </md-table>

    <Pagination
      :current-page="currentPage"
      :total-pages="totalPages"
      @change-page="changePage"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import { formatDateAndTime } from "@/helpers/formatDate";

export default {
  components: {
    Pagination: () => import("@/components/Ui/Pagination"),
  },

  data() {
    return {
      currentOrder: "asc",
      currentSort: "transactionId",
    };
  },

  computed: {
    ...mapGetters("transactions", ["dateBegin", "dateEnd", "limit"]),
    ...mapState(["isLoaderVisible"]),
    ...mapState("transactions", ["currentPage", "totalPages", "transactions"]),

    dateFrom: {
      get: function () {
        return this.dateBegin;
      },
      set: function (date) {
        this.setDateBegin(date);
      },
    },

    dateTo: {
      get: function () {
        return this.dateEnd;
      },
      set: function (date) {
        this.setDateEnd(date);
      },
    },
  },

  methods: {
    ...mapActions("transactions", ["getTransactions"]),
    ...mapMutations({
      setCurrentPage: "transactions/SET_CURRENT_PAGE",
      setDateBegin: "transactions/SET_DATE_BEGIN",
      setDateEnd: "transactions/SET_DATE_END",
      setOrderBy: "transactions/SET_ORDER_BY",
      setOrderDirection: "transactions/SET_ORDER_DIRECTION",
      setSkipTransactions: "transactions/SET_SKIP",
      showLoader: "SET_SHOW_LOADER",
    }),

    async changePage(page) {
      this.setCurrentPage(page);
      this.setSkipTransactions(this.limit * (page - 1));

      this.showLoader(true);
      await this.getTransactions();
      this.showLoader(false);
    },

    async customSort() {
      this.setOrderBy(this.currentSort);
      this.setOrderDirection(this.currentOrder);

      this.showLoader(true);
      await this.getTransactions();
      this.showLoader(false);
    },

    formatDate(dateString) {
      return formatDateAndTime(new Date(dateString));
    },

    getItemsCount(item, name) {
      let count = item[`${name}After`] - item[`${name}Before`];

      if (count > 0) {
        count = "+" + count;
      }

      return count;
    },
  },
};
</script>

<style lang="scss" scoped>
.md-toolbar-section-end > * {
  &:not(:last-child) {
    margin-right: 30px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset;
    right: 0;
  }

  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
